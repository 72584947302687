import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CryptoService } from './crypto.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class InterceptorService implements HttpInterceptor{
  private _URL_API: string = environment.URL_API;
  private urlExcluded = ['media/','users/logout', 'users/login', 'me', 'upload', 'contents'];
  constructor(
    private crypto: CryptoService
  ) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(req.url.indexOf(this._URL_API)==0){
      const upload_file = req.url.includes('/upload') || req.url.includes('/reports') || req.url.includes('/users/list') || req.url.includes('/comments');
      if(!this.validExclude(req.url) && !upload_file && req.method != 'PATCH'){
        let dataEncrypt:any = this.crypto.encryptObject(req.body);
        const newReq = req.clone({ body: {data:dataEncrypt}});
        return next.handle(newReq).pipe(
          map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
              let newEvent: HttpEvent<any>;
              if(event.body){
                if(event.body.data){
                    let decryptedData = this.crypto.decryptObject(event.body.data);
                    newEvent = event.clone({
                      body: decryptedData
                    });
                }
              }
              return newEvent;
            }else{
              return event;
            }
          })
        );
      }else{
        return next.handle(req);
      }
    }else{
      return next.handle(req);
    }
  }

  private validExclude(url:string):boolean{
    let subUrl=url.substring(this._URL_API.length,url.length);
    let include = false;
    for(let value of this.urlExcluded){
      if(subUrl.indexOf(value)==0){
        include=true;
      }
    }
    return include;
  }
}
